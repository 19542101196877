import React from "react";
import dagre from "dagre";
//import { DataManager, Query } from "@syncfusion/ej2-data";
import {
  MAX_INPUT_LENGHT,
  SCENARIO_BLOCK,
  LIBRARY_BLOCK,
  SCENARIO_ACTIVITY,
  LIBRARY_ACTIVITY,
  SCENARIO_GROUP,
  LIBRARY_GROUP,
  LIBRARY_CATEGORY, 
  LIBRARY_STRUCTURE,
  BLOCK_WIDTH,
  BLOCK_HEIGHT,
  LAYOUT_ONCE,
  LAYOUT_ALWAYS,
  LAYOUT_NEVER,
} from "../../global/constants";
import { getValue } from "@syncfusion/ej2-base";
import { allowFeature } from "../FeatureUtils.js/FeatureUtils";
import {
  NLC_DIMENSION,
  ONE_BLOCK_DUPLICATION_FEATURE,
} from "../../licenseData";
import {
  modifyDependencies,
  modifyActivities,
} from "../DependencyUtils/DependencyUtils";
import { findArraysDiffById } from "../FilterUtils/FilterUtils";

export const checkIfWeight0 = (favoriteBlocks) => {
  const tmpBLockList = favoriteBlocks.filter((block) => {
    if (
      block.customWorkWeight !== null &&
      (block.customWorkWeight === 0 || block.nonLaborCostWeight === 0)
    ) {
      return block;
    }
    return [];
  });
  if (tmpBLockList !== undefined && tmpBLockList.length > 0) {
    return true;
  } else {
    return false;
  }
};

export function getArrayOfAssignedAttribute(array) {
  return [
    ...new Set(
      array.filter((a) => a.attributeName !== null).map((a) => a.attributeName)
    ),
  ];
}

const customMaxLengthValidation = (args) => {
  return getValue("value", args).length <= MAX_INPUT_LENGHT;
};

export const hierarchicalColumns = [
  {
    field: "id",
    headerText: "id",
    textAlign: "left",
    width: 80,
    visible: false,
    isPrimaryKey: true,
    allowEditing: false,
    showInColumnChooser: false,
  },
  {
    field: "uxId",
    headerText: "#",
    textAlign: "left",
    width: 80,
    visible: true,
    allowEditing: false,
  },
  {
    field: "name",
    headerText: "Block Name",
    textAlign: "left",
    width: 250,
    validationRules: {
      required: true,
      maxLength: [customMaxLengthValidation, "Define a shorter name"],
    },
    editType: "textbox",
  },
  {
    field: "attributeName",
    headerText: "Block Input",
    textAlign: "left",
    width: 150,
    allowEditing: true,
    visible: false,
    validationRules: {
      required: false,
    },

    editType: "dropdownedit",
  },

  {
    field: "createdAt",
    headerText: "Created",
    textAlign: "left",
    type: "date",
    width: 180,
    validationRules: {
      required: true,
    },
    editType: "dateedit",
    visible: false,
    allowEditing: false,
  },
  {
    field: "type",
    headerText: "Type",
    textAlign: "left",
    width: 50,
    validationRules: {
      required: true,
    },
    editType: "textbox",
    visible: false,
    allowEditing: false,
  },
  /*{
    headerText: "Actions",
    textAlign: "center",
    width: 80,
    commands: [
       {
        buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        type: "Edit",
      },
     {
        type: "Delete",
        buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
      },
    ],
  },*/
];

export const blockScenariolColumns = [
  {
    field: "id",
    headerText: "id",
    textAlign: "left",
    width: 80,
    visible: false,
    isPrimaryKey: true,
    allowEditing: false,
    showInColumnChooser: false,
  },
  {
    field: "uxId",
    headerText: "#",
    textAlign: "left",
    width: 80,
    visible: true,
    allowEditing: false,
  },
  {
    field: "name",
    headerText: "Block Name",
    textAlign: "left",
    width: 250,
    allowEditing: true,
    validationRules: {
      required: true,
      maxLength: [customMaxLengthValidation, "Define a shorter name"],
    },
    editType: "textbox",
  },
  {
    field: "dimensions.customDurationWeight",
    headerText: "Duration (%)",
    textAlign: "center",
    edit: {
      params: { decimals: 2 },
    },
    visible: false,
    validationRules: {
      required: true,
      min: 0,
      max: 200,
    },
    width: 120,
    editType: "numericedit",
  },
  {
    field: "attributeName",
    headerText: "Associated Input",
    textAlign: "left",
    width: 100,
    allowEditing: false,
    visible: false,
    validationRules: {
      required: false,
    },
    editType: "dropdownedit",
  },
  {
    field: "createdAt",
    headerText: "Created",
    textAlign: "left",
    type: "date",
    width: 180,
    validationRules: {
      required: true,
    },
    editType: "dateedit",
    visible: false,
    allowEditing: false,
  },
  {
    field: "type",
    headerText: "Type",
    textAlign: "left",
    width: 50,
    validationRules: {
      required: true,
    },
    editType: "textbox",
    visible: false,
    allowEditing: false,
  },
  /*
  {
    headerText: "Actions",
    textAlign: "center",
    width: 40,
    commands: [
      /*{
        buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        type: "Edit",
      },
       {
        type: "Delete",
        buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
      },
  },*/
];

export const attributeWeightsColumns = [
  {
    field: "id",
    headerText: "id",
    textAlign: "left",
    visible: false,
    isPrimaryKey: true,
    allowEditing: false,
    showInColumnChooser: false,
  },
  {
    field: "uxId",
    headerText: "#",
    textAlign: "left",
    visible: false,
    allowEditing: false,
  },
  {
    field: "attributeName",
    headerText: "Block Input",
    textAlign: "left",
    width: 130,
    allowEditing: true,
    visible: true,
    validationRules: {
      required: true,
      maxLength: [customMaxLengthValidation, "Define a shorter name"],
    },
    editType: "dropdownedit",
  },
  {
    field: "name",
    headerText: "Block Name",
    textAlign: "left",
    width: 180,
    validationRules: {
      required: true,
    },
    editType: "textbox",
    allowEditing: true,
  },
  {
    headerText: "Planned Duration",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.customDurationWeight",
        allowEditing: true,
        headerText: "%",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
      {
        field: "dimensions.customRealDuration",
        allowEditing: false,
        headerText: "days",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
        },

        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Planned Work",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.customWorkWeight",
        allowEditing: true,
        headerText: "%",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
      {
        field: "dimensions.customRealWork",
        allowEditing: false,
        headerText: "hours",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
        },
        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Planned Non-Labor Cost",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.nonLaborCostWeight",
        headerText: "%",
        width: 60,
        textAlign: "center",
        visible: allowFeature(NLC_DIMENSION),
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
      {
        field: "dimensions.customRealNonLaborCost",
        allowEditing: false,
        headerText: "€",
        width: 60,
        textAlign: "center",
        visible: allowFeature(NLC_DIMENSION),
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
        },
        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Is",
    textAlign: "center",
    width: 100,
    columns: [
      {
        field: "allowDuplication",
        headerText: "Duplicable?",
        width: 50,
        allowEditing: true,
        visible: allowFeature(ONE_BLOCK_DUPLICATION_FEATURE),
        editType: "booleanedit",
        displayAsCheckBox: true,
        textAlign: "center",
      }
    ],
  },
];

export const attributeWeightsColumnsForDefinite = [
  {
    field: "id",
    headerText: "id",
    textAlign: "left",
    width: 80,
    visible: false,
    isPrimaryKey: true,
    allowEditing: false,
    showInColumnChooser: false,
  },
  {
    field: "uxId",
    headerText: "#",
    textAlign: "left",
    width: 50,
    visible: false,
    allowEditing: false,
  },
  {
    field: "attributeName",
    headerText: "Block Input",
    textAlign: "left",
    width: 130,
    allowEditing: true,
    visible: false,
    validationRules: {
      required: true,
      maxLength: [customMaxLengthValidation, "Define a shorter name"],
    },
    editType: "dropdownedit",
  },
  {
    field: "name",
    headerText: "Block Name",
    textAlign: "left",
    width: 180,
    validationRules: {
      required: true,
    },
    editType: "textbox",
    allowEditing: true,
  },
  {
    headerText: "Planned Duration",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.customDurationWeight",
        allowEditing: true,
        headerText: "%",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Planned Work",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.customWorkWeight",
        allowEditing: true,
        headerText: "%",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
      {
        field: "dimensions.customRealWork",
        allowEditing: false,
        headerText: "hours",
        width: 60,
        textAlign: "center",
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
        },
        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Planned Non-Labor Cost",
    textAlign: "center",
    width: 120,
    columns: [
      {
        field: "dimensions.nonLaborCostWeight",
        headerText: "%",
        width: 60,
        textAlign: "center",
        visible: allowFeature(NLC_DIMENSION),
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
          max: 200,
        },
        editType: "numericedit",
      },
      {
        field: "dimensions.customRealNonLaborCost",
        allowEditing: false,
        headerText: "€",
        width: 60,
        textAlign: "center",
        visible: allowFeature(NLC_DIMENSION),
        type: "number",
        edit: {
          params: { decimals: 2 },
        },
        validationRules: {
          required: true,
          min: 0,
        },
        editType: "numericedit",
      },
    ],
  },
  {
    headerText: "Is",
    textAlign: "center",
    width: 100,
    columns: [
      {
        field: "allowDuplication",
        headerText: "Duplicable?",
        width: 50,
        allowEditing: true,
        visible: allowFeature(ONE_BLOCK_DUPLICATION_FEATURE),
        editType: "booleanedit",
        displayAsCheckBox: true,
        textAlign: "center",
      }
    ],
  },
];

export const messageWhenNoDependencies = (number) => {
  if (number > 0) {
    return (
      <div
        className="alert justify-content-center alert-fourth font-weight-bold"
        role="alert"
      >
        Move items into grid and connect them with the nodes and connectors
      </div>
    );
  }
};

export const isGroupBlock = (block) =>
  block.type === SCENARIO_GROUP ||
  block.type === LIBRARY_GROUP ||
  block.type === LIBRARY_CATEGORY ||
  block.type === LIBRARY_STRUCTURE;

const getBlockWidth = (item) => {
  switch (item.type) {
    case SCENARIO_GROUP:
      return 662;
    case LIBRARY_GROUP:
      return 662;
    case LIBRARY_CATEGORY:
      return 662;
    case LIBRARY_STRUCTURE:
      return 662;
    default:
      return BLOCK_WIDTH;
  }
};

const getBlockHeight = (item) => {
  switch (item.type) {
    case SCENARIO_BLOCK:
      return 152;
    case LIBRARY_BLOCK:
      return 197;
    case SCENARIO_ACTIVITY:
      return 152;
    case LIBRARY_ACTIVITY:
      return 152;
    case SCENARIO_GROUP:
      return 200;
    case LIBRARY_GROUP:
      return 200;
    case LIBRARY_CATEGORY:
      return 200;
    case LIBRARY_STRUCTURE:
      return 200;
    default:
      return BLOCK_HEIGHT;
  }
};

const getBlockHeaderSpace = (item) => {
  switch (item.type) {
    case SCENARIO_BLOCK:
      return 103;
    case LIBRARY_BLOCK:
      return 150;
    case SCENARIO_GROUP:
      return 63;
    case LIBRARY_GROUP:
      return 63;
    case LIBRARY_CATEGORY:
      return 63;
    case LIBRARY_STRUCTURE:
      return 63;
    default:
      return 0;
  }
};

const isDefaultBlock = (block) => !isGroupBlock(block) && !block.parentNode;

// unfortunately we need this little hack to pass a slightly different position
// to notify react flow about the change. Moreover we are shifting the dagre node position
// (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
const buildDagreGraf = (
  items, 
  dependencies, 
  direction,
  groupBlocks = [],
  expandedBlocksIds = []
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 100,
    nodesep: 100,
    marginx: 100,
    marginy: 100,
  });

  let _deepItems = [];
   
  items.forEach((el) => {
    if (isGroupBlock(el)) {
      dagreGraph.setNode(el.id, {
        width: el.style?.width,
        height: el.style?.height,
      });
      const children = groupBlocks
        .find((g) => g.parent.id === el.id).children;
      _deepItems.push(...children);
    } else if (expandedBlocksIds.includes(el.id)) {
      dagreGraph.setNode(el.id, {
        width: el.style.width,
        height: el.style.height,
      });
    } else {
      dagreGraph.setNode(el.id, {
        width: getBlockWidth(el),
        height: getBlockHeight(el),
      });
    }
  });

  dependencies.forEach((dep) => {
    const source =
      items.find((el) => el.id === dep.source)?.id ||
      _deepItems.find((el) => el.id === dep.source)?.parentNode;
    const target =
      items.find((el) => el.id === dep.target)?.id ||
      _deepItems.find((el) => el.id === dep.target)?.parentNode;

    if (source && target) {
      dagreGraph.setEdge(source, target);
    }
  });

  dagre.layout(dagreGraph);
  return dagreGraph;
};

const getFullLayout = (
  blocks, 
  edges, 
  activities, 
  activityEdges,
  direction = "LR", 
  expandedGroupIds = [],
  expandedBlocksIds = []
) => {
  if (!blocks?.length) {
    return [];
  }

  const isHorizontal = direction === "LR";
  const levels = {};

  // remove old styles
  blocks = blocks.map(item => {
    delete item.style["width"];
    delete item.style["height"];
    return item;
  });

  // filter group block elements and its child blocks
  let groupBlocks = [];
  blocks
    .filter((n) => isGroupBlock(n))
    .filter((n) => !n.parentNode || expandedGroupIds.includes(n.parentNode))
    .sort((a, b) => {
      if (a.parentNode && !b.parentNode) return -1;
      if (a.parentNode === b.id) return -1;
      else return 0;
    })
    .forEach((group) => {
      levels[group.id] = -1;
      groupBlocks.push({
        parent: group,
        children: expandedGroupIds.includes(group.id) ?
          blocks.filter((el) => el.parentNode === group.id) : [],
      });
    });

  // validate list of groups
  groupBlocks = groupBlocks.filter(group => {
    return !!group.parent.parentNode ===
     !!groupBlocks.find(g => g.parent.id === group.parent.parentNode)
  });

  // filter expanded blocks and its activities  
  let expandedBlocks = blocks
    .filter((n) => !isGroupBlock(n) && expandedBlocksIds.includes(n.id))
    .filter((n) => 
      !n.parentNode || (
        groupBlocks.find(b => b.parent.id == n.parentNode) && 
        expandedGroupIds.includes(n.parentNode)
      )
    )
    .map((block) => {
      return {
        parent: block,
        children: activities.filter(a => a.block.id == block.id),
      }
    });

  // define positions of activities and styles of expanded blocks
  expandedBlocks.map((el) => {
    const dagreGraph = buildDagreGraf(
      el.children, 
      activityEdges, 
      direction
    );
    el.children.map((e) => {
      const rect = dagreGraph.node(e.id);
      e.position = {
        x: rect.x - rect.width / 2,
        y: rect.y - rect.height / 2 + getBlockHeaderSpace(el.parent) - 30
      };
      return e;
    });

    if (!el.parent.style) el.parent.style = {};

    if (el.children.length) {
      el.parent.style.width = dagreGraph.graph().width;
      el.parent.style.height = dagreGraph.graph().height + getBlockHeaderSpace(el.parent) - 60;
    } else {
      el.parent.style.width = getBlockWidth(el.parent);
      el.parent.style.height = getBlockHeight(el.parent);
    }

    return el;
  });

  // merge with extended blocks 
  blocks = blocks.map(block => {
    const index = expandedBlocks.findIndex(b => b.id === block.id);
    if (index < 0) return block;
    return expandedBlocks[index].parent;
  });

  // define positions of child blocks and styles of groups
  groupBlocks.map((el) => {
    const dagreGraph = buildDagreGraf(
      el.children, 
      edges, 
      direction, 
      groupBlocks, 
      expandedBlocksIds
    );
    el.children.map((e) => {
      const rect = dagreGraph.node(e.id);
      e.position = {
        x: rect.x - rect.width / 2,
        y: rect.y - rect.height / 2 + getBlockHeaderSpace(el.parent)
      };
      return e;
    });

    if (!el.parent.style) el.parent.style = {};
    
    if (el.children.length) {
      el.parent.style.width = dagreGraph.graph().width;
      el.parent.style.height = dagreGraph.graph().height  + getBlockHeaderSpace(el.parent);
    } else {
      el.parent.style.width = getBlockWidth(el.parent);
      el.parent.style.height = getBlockHeight(el.parent);
    }

    if (el.parent.parentNode) {
      levels[el.parent.parentNode] -= 1;
    }

    el.parent.style.zIndex = levels[el.parent.id];

    return el;
  });

  // filter block elements without nesting
  let defaultBlocks = blocks.filter((n) => isDefaultBlock(n));

  // merge top level groups and blocks without nesting
  const parentBlocks = groupBlocks
    .map((group) => group.parent)
    .filter((group) => !group.parentNode);
  let allBlocks = defaultBlocks.concat(parentBlocks);

  // define positions of groups and blocks without nesting
  const dagreGraph = buildDagreGraf(
    allBlocks, 
    edges,
    direction, 
    groupBlocks, 
    expandedBlocksIds
  );

  allBlocks.map((el, i, array) => {
    if (!isGroupBlock(el)) {
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";
    }

    const rect = dagreGraph.node(el.id);
    el.position = {
      x: rect.x - rect.width / 2,
      y: rect.y - rect.height / 2,
    };

    return el;
  });

  // add activities for the common array
  expandedBlocks
    .map((group) => group.children)
    .forEach((children) => (allBlocks = allBlocks.concat(children)));

  // add child blocks for the common array
  groupBlocks
    .map((group) => group.children)
    .forEach((children) => (allBlocks = allBlocks.concat(children)));

  return allBlocks;
};

const getActivitiesLayout = ( 
  activities, 
  activityEdges,
  direction = "LR", 
) => {
  if (!activities?.length) {
    return [];
  }

  const dagreGraph = buildDagreGraf(activities, activityEdges, direction);
  
  return activities.map((el) => {
    const rect = dagreGraph.node(el.id);
    el.parentNode = null;
    el.position = { x: rect.x, y: rect.y };
    return el;
  });
};

export const layoutFullFlow = (
  props, 
  nodes,
  direction, 
  expandedGroups,
  expandedBlocks,
  relayout = false
) => {
  const {
    items = [],
    dependencies = [],
    activities = [], 
    activityDependencies = [],
    itemsLayouting,
    activityType,
  } = props;

  let _blocks = setItemsProps(items);
  let _activities = modifyActivities(activities, activityType)

  let _blocksDeps = removeOldDependencies(modifyDependencies(dependencies), _blocks);
  let _activitiesDeps = removeOldDependencies(modifyDependencies(activityDependencies), _activities);
    
  let mergedItems = [..._blocks, ..._activities];
  let mergedDeps = [..._blocksDeps, ..._activitiesDeps];
  
  const layoutingData = [
    _blocks, 
    _blocksDeps, 
    _activities, 
    _activitiesDeps, 
    direction, 
    expandedGroups, 
    expandedBlocks
  ];

  if (itemsLayouting === LAYOUT_ONCE) {
    mergedItems = !relayout
      ? setNewItems([...nodes], mergedItems)
      : getFullLayout(...layoutingData);
  } else if (itemsLayouting === LAYOUT_ALWAYS) {
    mergedItems = getFullLayout(...layoutingData);
  } else if (itemsLayouting === LAYOUT_NEVER) {
    mergedItems = nodes.length ? setNewItems([...nodes], mergedItems) : mergedItems;
  }

  return { 
    nodes: mergedItems.map((node) => {
      node.style = { ...node.style };
      return node;
    }), 
    edges: mergedDeps 
  };
} 

export const layoutActivityFlow = (
  props, 
  nodes,
  direction, 
  relayout = false
) => {
  const {
    activities = [], 
    activityDependencies = [],
    itemsLayouting,
    activityType,
  } = props;
  let _activities = modifyActivities(activities, activityType)
  let _activitiesDeps = removeOldDependencies(modifyDependencies(activityDependencies), _activities);

  const layoutingData = [_activities, _activitiesDeps, direction];
  let layoutedActivities = _activities;
  let layoutedActivitiesDeps = _activitiesDeps;

  if (itemsLayouting === LAYOUT_ONCE) {
    layoutedActivities = !relayout
      ? setNewItems([...nodes], _activities)
      : getActivitiesLayout(...layoutingData);
  } else if (itemsLayouting === LAYOUT_ALWAYS) {
    layoutedActivities = getActivitiesLayout(...layoutingData);
  } else if (itemsLayouting === LAYOUT_NEVER) {
    layoutedActivities = nodes.length ? 
      setNewItems([...nodes], layoutedActivities) : layoutedActivities;
  }

  return { 
    nodes: layoutedActivities, 
    edges: layoutedActivitiesDeps 
  };
} 

export const setItemsProps = (items) => {
  return items.map((item) => {
    if (isGroupBlock(item)) {
      if (!item.style) item.style = {};
      item.style.background = item.style.background || "rgba(240,240,240,0.5)";
      item.style.border = item.style.border || "1px solid gray";
      item.style.borderRadius = item.style.borderRadius || "10px";
    } else {
      if (!item.style) item.style = {};
      item.style.border = "1px solid #491777";
      item.style.borderRadius = "16px";
      item.style.pointerEvents = "none";
    }
    return item;
  });
};

export const modifyNewItem = (item, blockType) => {
  return {
    id: String(item.id),
    type: blockType || LIBRARY_BLOCK,
    data: { 
      label: item.name,
      structureId: item.structure.id.toString()
    },
    parentNode: item.parentId ? String(item.parentId) : null,
    expandParent: item.parentId ? "true" : null,
    extent: item.parentId ? "parent" : null,
    position: { x: 100, y: 150 },
    style: {
      border: "1px solid #491777",
      borderRadius: "16px",
    },
  };
};

export const removeOldDependencies = (deps, blocks) => {
  return deps.filter((dep) => {
    return (
      blocks.find((el) => el.id == dep.source && !isGroupBlock(el)) &&
      blocks.find((el) => el.id == dep.target && !isGroupBlock(el))
    );
  });
}; // TODO this function is temporary

export const setNewItems = (oldItems, newItems) => {
  if (oldItems.length < newItems.length) {
    const newItem = newItems.find((i) => {
      return !oldItems.map((x) => x.id).includes(i.id);
    });

    if (newItem) {
      oldItems.push(newItem);
    }

    return oldItems;
  } else if (oldItems.length > newItems.length) {
    return oldItems.filter((i) => newItems.map((x) => x.id).includes(i.id));
  }

  return oldItems;
};

export const getBlockChildrenDeep = (blocks, blockId) => {
  let childrenId = [];

  const findChildren = (id) => {
    if (!id) return [];

    return blocks.filter((item) => item.parentId === id).map((item) => item.id);
  };

  let _childrenId = findChildren(blockId);
  while (_childrenId.length) {
    childrenId = childrenId.concat(_childrenId);
    let __childrenId = [];
    _childrenId.forEach((childId) => {
      __childrenId = __childrenId.concat(findChildren(childId));
    });
    _childrenId = __childrenId;
  }

  return childrenId;
};

export const isDeletionError = (
  allBlocks,
  blockIdToDelete,
  allowItemDeletion
) => {
  const block = allBlocks.find(b => b.id === blockIdToDelete);
  const siblingBlocks = allBlocks.filter(b => 
    b.type === block.type && b.parentNode === block.parentNode
  );

  if (!allowItemDeletion) {
    return "You cannot delete this block: go to related scope to do this.";
  } else if (siblingBlocks.length === 1) {
    return "You cannot delete this. We must keep at least one item!";
  }

  return;
};

export const convertBlockToPositionDTO = (block) => {
  return {
    id: block.id,
    position: block.position,
  };
};

export const convertBlocksToDimensionDTO = (blocks) => {
  return blocks.map((b) => {
    return {
      id: b.id,
      name: b.name,
      allowDuplication: b.allowDuplication,
      isInterfacable: b.isInterfacable,
      dimensions: b.dimensions,
    };
  });
};

export const enableScopeChangeFeature = (items) => {
  const limitToEnableSimulation = 3;
  if (items && items.length > 0) {
    if (
      items.filter((i) => i.blockId == null).length > limitToEnableSimulation
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const BlockStatus = {
  collapsed: "collapsed", 
  expanded: "expanded",
  loading: "loading",
  empty: "empty"
}

export const getBlockStatus = (status) => {
  switch (status) {
    case BlockStatus.collapsed: 
      return <span style={{ padding: "0 12px"}}/>;
    case BlockStatus.loading: 
      return <i className="fas fa-circle-notch fa-spin mx-1" style={{ color: "gray"}}/>;
    case BlockStatus.empty:
      return <span style={{ color: "red", fontSize: "0.7rem"}}>empty</span>;
    case BlockStatus.expanded:
      return <></>;
    default: 
      return <></>;
  }
}

export const convertLibraries = (libraries) => {
  return libraries.map(library => {
    return {
      id: library.name,
      type: LIBRARY_CATEGORY,
      data: { 
        label: library.name, 
        description: library.description, 
        libraryId: library.id,
        type: library.type
      },
      style: {
        background: "rgba(143,0,255,0.03)",
        border: "1px solid #8f00ff",
        borderRadius: "10px",
      }
    }
  })
}

export const convertStructures = (structures, standalone = false) => {
  return structures.map(structure => {
    return {
      id: structure.id.toString(),
      type: LIBRARY_STRUCTURE,
      data: { 
        label: structure.name,
        structureType: structure.structureType,
        description: structure.description,
        library: !standalone ? structure.library : undefined,
        codeStructureId: structure.codeStructureId
      },
      parentNode: !standalone ? structure.library.name : undefined,
      extent: !standalone ? "parent" : undefined,
      expandParent: !standalone ? "true" : undefined,
      style: {
        background: "rgba(0,0,240,0.03)",
        border: "1px solid blue",
        borderRadius: "10px",
      }
    }
  })
}

export const addParentToBlocksSubflow = (blocks, parentId) => {
  return blocks.map(block => {
    block.parentNode = block.parentNode || parentId;
    block.extent = "parent";
    block.expandParent = "true";
    return block;
  });
}

const getVisibleBlocks = (blocks, activities, expandedGroupIds, expandedBlocksIds) => {
  if (!blocks?.length) {
    return [];
  }

  const levels = {};

  // filter group block elements and its child blocks
  let groupBlocks = [];
  blocks
    .filter((n) => isGroupBlock(n))
    .filter((n) => !n.parentNode || expandedGroupIds.includes(n.parentNode))
    .sort((a, b) => {
      if (a.parentNode && !b.parentNode) return -1;
      if (a.parentNode === b.id) return -1;
      else return 0;
    })
    .forEach((group) => {
      levels[group.id] = -1;
      groupBlocks.push({
        parent: group,
        children: expandedGroupIds.includes(group.id) ?
          blocks.filter((el) => el.parentNode === group.id) : [],
      });
    });

  // validate list of groups
  groupBlocks = groupBlocks.filter(group => {
    return !!group.parent.parentNode ===
     !!groupBlocks.find(g => g.parent.id === group.parent.parentNode)
  });

  // filter expanded blocks and its activities  
  let expandedBlocks = blocks
    .filter((n) => !isGroupBlock(n) && expandedBlocksIds.includes(n.id))
    .filter((n) => 
      !n.parentNode || (
        groupBlocks.find(b => b.parent.id == n.parentNode) && 
        expandedGroupIds.includes(n.parentNode)
      )
    )
    .map((block) => {
      return {
        parent: block,
        children: activities.filter(a => a.block.id == block.id),
      }
    });

  // merge with extended blocks 
  blocks = blocks.map(block => {
    const index = expandedBlocks.findIndex(b => b.id === block.id);
    if (index < 0) return block;
    return expandedBlocks[index].parent;
  });

  // filter block elements without nesting
  let defaultBlocks = blocks.filter((n) => isDefaultBlock(n));

  // merge top level groups and blocks without nesting
  const parentBlocks = groupBlocks
    .map((group) => group.parent)
    .filter((group) => !group.parentNode);
  let allBlocks = defaultBlocks.concat(parentBlocks);

  // add activities for the common array
  expandedBlocks
    .map((group) => group.children)
    .forEach((children) => (allBlocks = allBlocks.concat(children)));

  // add child blocks for the common array
  groupBlocks
    .map((group) => group.children)
    .forEach((children) => (allBlocks = allBlocks.concat(children)));

  return allBlocks;
}

export const shouldRelayout = (
  blocks,
  activities,
  dependencies,
  activityDependencies,
  expandedGroupIds, 
  expandedBlocksIds,
  reactFlowInstance
) => {
  const nodes = reactFlowInstance.current?.getNodes() || [];
  const edges = reactFlowInstance.current?.getEdges() || [];

  const blocksDeps = removeOldDependencies(modifyDependencies(dependencies), blocks);
  const activitiesDeps = removeOldDependencies(modifyDependencies(activityDependencies), activities);

  const mergedDeps = [...blocksDeps, ...activitiesDeps];
  const visibleBlocks = getVisibleBlocks(blocks, activities, expandedGroupIds, expandedBlocksIds);

  const blocksAreChanged = nodes.length !== visibleBlocks.length;
  const depsToAdd = findArraysDiffById(mergedDeps, edges); 
  const depsToRemove = findArraysDiffById(edges, mergedDeps);

  if (depsToAdd.length === 1 && depsToAdd[0].status === "new" && !blocksAreChanged) {
    reactFlowInstance.current.addEdges(depsToAdd);
    return false;
  } else if (depsToRemove.length === 1 && !blocksAreChanged) {
    reactFlowInstance.current.deleteElements({ edges: depsToRemove });
    return false;
  }

  return true;
}

export const animateCloseButton = (id) => {
  try {
    const node = document.querySelector(`div.react-flow__node[data-id="${id}"]`);

    const button = node.querySelector("button.custom_close");
    button.innerHTML = "<i class='b-fa b-fa-circle-notch fa-spin'/>";

    node.style.pointerEvents = "none";
    node.querySelector("div.custom_dataBlock").style.pointerEvents = "none";
    node.querySelector("div.react-flow__handle-left").style.pointerEvents = "none";
    node.querySelector("div.react-flow__handle-right").style.pointerEvents = "none";
  } catch (e) { }
}

/**      edit: {
        params: {
          actionComplete: () => false,
          allowFiltering: false,
          dataSource: inputList.map((input) => {
            return { name: input.name };
          }),
          fields: { value: "name" },
        },
      }, */
